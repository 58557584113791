<template>
  <defaultFieldTypes
    :fieldAttributes="localFieldAttributes"
    :field="field"
    :templateContent="result"
    v-on="$listeners"
    :checkReadOnly="checkReadOnly"
    :value="value"
  ></defaultFieldTypes>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";

export default {
  props: ["result", "field", "fieldAttributes", "value", "checkReadOnly"],
  components: { defaultFieldTypes },
  computed: {
    localFieldAttributes() {
      let localFieldAttributes = { ...this.fieldAttributes };
      if (this.result.valueLine.id == "") {
        this.$set(localFieldAttributes.fieldAttrInput, "class", "required");
      } else {
        this.$set(localFieldAttributes.fieldAttrInput, "class", "");
      }
      return localFieldAttributes;
    },
  },
};
</script>

